<template>
  <div>
    <iframe id="inlineFrameExample"
   
    :src="'https://rhino.sens-eye.fr/examples/meyro/?id_cart='+$route.params.id+'&token='+token">
    </iframe>
  </div>
</template>

<script>

import {APIRequest} from "@simb2s/senseye-sdk"


export default {
    name: 'PersonnalisationMeyro',
    components: {
        

    },
    data() {
        return {
          redirected:false,
          token:localStorage.token_senseye_auth
        }
    },
    methods:{
        orderCreated(id) {
          this.$router.push({name:'order-review', params: { id: id }})
        }  
    },
    async mounted()
    {
      localStorage.order_review_reloaded=false
      window.addEventListener('message', function (e) {
          if (e.data.event === 'order-success') {
            if(this.redirected==false)
            {
              console.log('order created vue')
              console.log(e.data.data);
              this.redirected=true
              this.orderCreated(e.data.data)
            }
           
          }
      }.bind(this));
        
    }
}
</script>
<style scoped>
#inlineFrameExample {
    width:100vw;
    height:100vh;
    border:0;
}
</style>